<article data-filterMenuComponent (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "filter.menu.title" | translate | async }}
    </h1>
    <div mat-dialog-content>
        <p>
            {{ "filter.menu.content.p1" | translate | async }}
        </p>
        <p>
            {{ "filter.menu.content.p2" | translate | async }}
        </p>
        <fieldset class="fieldset">
            <legend>
                {{ "filter.menu.legend" | translate | async }}
            </legend>
            <section *ngIf="labelList" [formGroup]="labelList">
                <ng-container *ngIf="labels.length; else noLabels">
                    <p *ngFor="let key of object.keys(labelList.controls); let i = index">
                        <mat-checkbox role="checkbox" attr.aria-label="{{ labelName(key) }}" (change)="syncData()" [formControlName]="key">{{ labelName(key) }}</mat-checkbox>
                    </p>
                </ng-container>
                <ng-template #noLabels>
                    <p>
                        {{ "filter.menu.no.label" | translate | async }}
                    </p>
                </ng-template>
            </section>
        </fieldset>
    </div>
    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child">
            <button type="button" class="button button--green-secondary" mat-stroked-button (click)="openManageLabelsDialog()">
                {{ "dialog.action.manage.labels" | translate | async }}
            </button>
            <button type="button" class="button button--green-secondary" mat-stroked-button [disabled]="!labelSelected" (click)="clearAll()">
                {{ "dialog.action.clear.all" | translate | async }}
            </button>
            <button type="button" class="button button--black-primary" mat-stroked-button (click)="trigger.closeMenu()">
                {{ "dialog.action.close" | translate | async }}
            </button>
        </div>
    </div>
</article>
