<article data-copyWorksheetComponent>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>

    <div mat-dialog-content [formGroup]="f">
        <p class="flex-child flex-child--full-width">
            {{ "calculator.copy.dialog.desc" | translate : [srcWorksheetName] | async }}
        </p>

        <div class="flex-child flex-child--full-width">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{ "calculator.copy.dialog.name" | translate | async }}</mat-label>
                <input matInput autoFocusByContent formControlName="worksheetName" maxlength="30" placeholder="{{ 'calculator.copy.dialog.name.placeholder' | translate | async }}" />
                <mat-error *ngIf="worksheetName.errors?.minlength">
                    {{ "validation.message.minLength.3" | translate | async }}
                </mat-error>
                <mat-error *ngIf="worksheetName.errors?.maxlength">
                    {{ "validation.message.maxLength.30" | translate | async }}
                </mat-error>
                <mat-error *ngIf="worksheetName.errors?.empty">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button mat-button class="button button--black-secondary" (click)="cancel()">
                {{ data.btnCancel | translate | async }}
            </button>
            <button mat-button class="button button--green-primary" (click)="copyWorksheet()">
                {{ data.btnOk | translate | async }}
            </button>
        </div>
    </div>
</article>
