<article *ngIf="data" data-flagDialogComponent>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "flag.info" | translate | async }}
    </h1>
    <div mat-dialog-content class="flex flex--cols-2">
        <mat-icon class="{{ data.flagLevelId | flagIconClassPipeNoButton }} flex-child flex-child--flex-shrink">
            {{ data.flagLevelId | flagIconPipe }}
        </mat-icon>
        <p class="flex-child flex-child--flex-grow">
            <!-- use the description from the master calc -->
            <span *ngIf="data.description" class="font-weight--medium">
                {{ data.description[lang$ | async] }}
            </span>
            <!-- use the i18n key if supplied -->
            <span *ngIf="data.descriptionI18nKey" class="font-weight--medium">
                {{ data.descriptionI18nKey | translate | async }}
            </span>
            ({{ data.flagLevelId | flagLevelNamePipe | translate | async }})
        </p>
        <p class="flex-child flex-child--full-width">
            {{ message$ | async }}
        </p>
        <p class="flex-child flex-child--full-width" *ngIf="isAuthenticated && data.allowComment === true">
            <app-flag-comment-input [calculatedFlag]="data"></app-flag-comment-input>
        </p>
    </div>
    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--black-primary" (click)="close()">
                {{ "dialog.action.close" | translate | async }}
            </button>
        </div>
    </div>
</article>
