<article data-createNewDialogComponent>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>
    <div mat-dialog-content>
        <div class="flex-child flex-child--full-width">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{ "calculator.create.select.type" | translate | async }}</mat-label>
                <mat-select [formControl]="worksheetType">
                    <mat-option value="">{{ "calculator.create.option.none" | translate | async }}</mat-option>
                    <mat-optgroup *ngFor="let group of worksheetTypeGroups" label="{{ 'calculator.type.category.' + group.category | translate | async }}">
                        <mat-option *ngFor="let worksheetType of group.worksheetTypes" [value]="worksheetType.id" class="wrap-option">
                            {{ worksheetType.description[lang$ | async] }}
                            <span *ngIf="isInprogress(worksheetType.id) || isUnavailable(worksheetType.id)"> ({{ "calculator.create.coming.soon" | translate | async }}) </span>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
        <p class="flex-child flex-child--full-width" data-worksheetTypeDetails>
            {{ worksheetTypeDetails }}
        </p>
    </div>
    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button mat-button class="button button--black-secondary" (click)="cancel()">
                {{ data.btnCancel | translate | async }}
            </button>
            <button mat-button (click)="createWorksheet()" class="button button--green-primary" [disabled]="!canCreate">
                {{ "dialog.action.create" | translate | async }}
            </button>
        </div>
    </div>
</article>
