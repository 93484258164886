<article *ngIf="formReady; else spinner" data-addCropRotationDialogComponent>
	<h1 mat-dialog-title class="h1 h1--component-title">
		{{ 'add.crop.rotation' | translate | async }}
	</h1>

	<div mat-dialog-content>
		<div [formGroup]="f" class="flex flex--cols-2" data-addCropRotationDialog>
			<mat-form-field appearance="outline" class="flex-child" color="accent">
				<mat-label>
					{{ 'crop.rotation.label' | translate | async }}
				</mat-label>

				<mat-select formControlName="cropRotationId" (selectionChange)="onCropRotationChange($event)">
					<mat-option *ngFor="let item of allCropRotations$ | async | orderBy: 'displayOrder'"
						[value]="item.cropRotationId">
						{{ item.description[lang$ | async] }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="flex-child flex-child--flex-placeholder" aria-hidden="true">
				&nbsp;
			</div>

			<h2 class="h2 h2--underlined-heading flex-child flex-child--full-width">
				{{ 'customize.this.rotation' | translate | async }}
			</h2>

			<div class="flex-child flex flex--cols-2 flex--nowrap">
				<mat-form-field appearance="outline" class="flex-child flex-child--flex-shrink" color="accent">
					<mat-label>
						{{ 'number.of.crops.label' | translate | async }}
					</mat-label>

					<mat-select formControlName="numberOfYears">
						<mat-option *ngFor="let item of numbers" [value]="item">
							{{ item }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="flex-child flex-child--flex-grow" color="accent">
					<mat-label>
						{{ 'first.year.in.rotation.label' | translate | async }}
					</mat-label>

					<mat-select formControlName="croppingYear">
						<mat-option *ngFor="let item of range" [value]="item">
							{{'fall' | translate | async}} {{ item - 1 }} - {{'fall' | translate | async}} {{ item }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="flex-child flex-child--flex-placeholder" aria-hidden="true">
				&nbsp;
			</div>

			<ng-container *ngFor="let i of numbers">
				<ng-container *ngIf="i <= numberOfYears.value">
					<mat-form-field appearance="outline" class="flex-child" color="accent">
						<mat-label>
							{{ 'calculator.cropnutri.cropType.label' | translate | async }} ({{i}})
						</mat-label>

						<mat-select formControlName="cropTypeId{{i}}" (selectionChange)="onCropTypeChange(i, $event)">
							<mat-option
								*ngFor="let item of allCropTypes$ | async | orderBy: (lang$ | async) === 'en' ? 'name.en' : 'name.fr'"
								[value]="item.cropTypeId">
								{{ item.name[lang$ | async] }}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="outline" class="flex-child" color="accent">
						<mat-label>
							{{ 'calculator.cropnutri.cropSubtype.label' | translate | async }}
							({{i}})
						</mat-label>

						<mat-select formControlName="cropSubType{{i}}" [compareWith]="compareSubTypes">
							<mat-option
								*ngFor="let item of cropSubTypesMap.get(i) | orderBy: (lang$ | async) === 'en' ? 'name.en' : 'name.fr'"
								[value]="item">
								{{ item.name[lang$ | async] }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div mat-dialog-actions class="flex flex--end-vertical">
		<div class="flex-child flex-child--flex-shrink">
			<button type="button" mat-stroked-button class="button button--black-secondary" (click)="close()">
				{{ 'dialog.action.cancel' | translate | async }}
			</button>
		</div>

		<div class="flex-child flex-child--flex-shrink">
			<button type="button" mat-stroked-button class="button button--green-primary" [mat-dialog-close]="f.value">
				{{ 'dialog.action.add' | translate | async }}
			</button>
		</div>
	</div>
</article>

<ng-template #spinner>
	{{ 'loading' | translate | async }}...
</ng-template>