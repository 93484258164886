<article data-confirmationDialogComponent>
    <h1 mat-dialog-title class="h1 h1--component-title">
        <ng-container *ngIf="data.title === null">
            {{ "dialog.title.info" | translate | async }}
        </ng-container>

        <ng-container *ngIf="data.title !== null">
            {{ data.title | translate | async }}
        </ng-container>
    </h1>

    <p *ngIf="!data.isArray" mat-dialog-content innerHTML="{{ data.message | translate | async }} {{ data.msgSuffix }} {{ data.customMessage$ | async }}">
        {{ data.message | translate | async }} {{ data.msgSuffix }} {{ data.customMessage$ | async }}
    </p>
    <div *ngIf="data.isArray" mat-dialog-content>
        <!-- <pre>{{data.message | json}}</pre> -->
        <div *ngFor="let item of data.message">
            <label *ngIf="item.heading">{{ item.heading | translate | async }}</label>
            <ul *ngIf="item.bullets">
                <li *ngFor="let bullet of item.bullets">{{ bullet | translate | async }}</li>
            </ul>
            <p *ngFor="let paragraph of item.paragraphs">{{ paragraph | translate | async }}</p>
        </div>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" *ngIf="data.displayBtnOk" class="button {{ data.btnOkStyle }}" mat-stroked-button [mat-dialog-close]="true" cdkFocusInitial>
                {{ data.btnOk | translate | async }}
            </button>
        </div>

        <div class="flex-child flex-child--flex-shrink">
            <button type="button" class="button" [ngClass]="data.displayBtnOk ? 'button--black-secondary' : 'button--black-primary'" mat-stroked-button (click)="onNoClick()">
                {{ data.btnCancel | translate | async }}
            </button>
        </div>
    </div>
</article>
