import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '.menu-action-btn'
})
export class MenuActionBtnDirective {
  @HostListener('click', ['$event']) onClick(event: any) {
    event?.stopPropagation();
  }
  @HostListener('keydown.enter', ['$event']) onEnter(event: any) {
    event?.stopPropagation();
  }
  @HostListener('keydown.space', ['$event']) onSpace(event: any) {
    event?.stopPropagation();
  }
}
