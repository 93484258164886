<div data-horizontalGradientBarComponent class="flex-child flex-child--full-width">
    <div class="gradient-bar-outer-wrapper flex flex-child flex--cols-3">
        <div *ngIf="leftLabel" class="gradient-left-label flex-child flex-child--flex-shrink">{{ leftLabel | translate | async }}</div>

        <div class="gradient-bar-inner-wrapper flex-child flex-child--flex-grow">
            <div class="gradient-bar" [style]="gradientBackground">
                <div
                    *ngIf="topIndicator"
                    class="indicator-wrapper"
                    [style.left]="dialPosition(topIndicator.percentage)"
                    [attr.aria-valuetext]="topIndicator.displayWith(topIndicator.value)"
                    [attr.aria-valuenow]="topIndicator.value">
                    <div class="dial top">
                        <div class="indicator top"></div>
                        <div class="label top" [class.float--left]="topIndicator.percentage > 50">{{ topIndicator.displayWith(topIndicator.value) }}</div>
                    </div>
                </div>
                <div
                    *ngIf="bottomIndicator"
                    class="indicator-wrapper bottom"
                    [style.left]="dialPosition(bottomIndicator.percentage)"
                    [attr.aria-valuetext]="bottomIndicator.displayWith(bottomIndicator.value)"
                    [attr.aria-valuenow]="bottomIndicator.value">
                    <div class="dial bottom">
                        <div class="indicator bottom"></div>
                        <div class="label bottom" [class.float--left]="bottomIndicator.percentage > 50">{{ bottomIndicator.displayWith(bottomIndicator.value) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="rightLabel" class="gradient-right-label flex-child flex-child--flex-shrink">{{ rightLabel | translate | async }}</div>
    </div>
</div>
